import {
  AuthGuard,
  AuthPipe,
  redirectLoggedInTo,
  redirectUnauthorizedTo,
} from '@angular/fire/auth-guard';
import { Route } from '@angular/router';
import {
  resetPasswordGuard,
  resetPasswordResolver,
} from '@dmc-console/reset-password';
import { shareCountingVersionGuard } from '@dmc-console/share';
import {
  appReadyGuard,
  organizationAndAccountGuard,
  redirectOrganizationOverviewGuard,
  RoutingUtils,
} from '@dmc-console/shared';

import { environment } from './config/environment';

const redirectUnauthorizedToLogin = (): AuthPipe =>
  redirectUnauthorizedTo(['login']);

const redirectLoggedInToOverview = (): AuthPipe => redirectLoggedInTo('/');

export const appRoutes: Route[] = [
  {
    path: 'login',
    loadComponent: () =>
      import('@dmc-console/login').then((m) => m.LoginComponent),
    canActivate: [AuthGuard],
    data: {
      authGuardPipe: redirectLoggedInToOverview,
    },
  },
  {
    path: 'reset-password',
    loadComponent: () =>
      import('@dmc-console/reset-password').then(
        (m) => m.ResetPasswordComponent,
      ),
    canActivate: [resetPasswordGuard],
    resolve: {
      resetPasswordData: resetPasswordResolver,
    },
  },
  {
    path: 'share/:token',
    loadComponent: () =>
      import('@dmc-console/share').then((m) => m.ShareComponent),
    canActivate: [shareCountingVersionGuard],
  },
  {
    path: '403',
    title: 'Access Denied',
    loadComponent: () =>
      import('@dmc-console/view').then((m) => m.Page403Component),
  },
  {
    path: '404',
    title: 'Page not found',
    loadComponent: () =>
      import('@dmc-console/view').then((m) => m.Page404Component),
  },
  {
    path: '',
    canActivate: [AuthGuard, appReadyGuard],
    title: 'Dekuple',
    loadComponent: () =>
      import('@dmc-console/view').then((m) => m.ContainerComponent),
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
    children: [
      {
        path: '',
        canActivate: [redirectOrganizationOverviewGuard],
        children: [],
      },
      {
        path: 'account-management',
        loadChildren: () =>
          import('@dmc-console/account-management').then(
            (m) => m.accountManagementRoutes,
          ),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('@dmc-console/profile').then((m) => m.profileRoutes),
      },
      {
        path: ':organizationId',
        loadComponent: () =>
          import('@dmc-console/view').then(
            (m) => m.OrganizationContainerComponent,
          ),
        canActivate: [organizationAndAccountGuard],
        runGuardsAndResolvers: 'always',
        children: [
          { path: '', redirectTo: 'overview', pathMatch: 'full' },
          {
            path: 'overview',
            loadChildren: () =>
              import('@dmc-console/overview').then((m) => m.overviewRoutes),
          },
          {
            path: 'home',
            loadChildren: () =>
              import('@dmc-console/home').then((m) => m.homeRoutes),
          },
          {
            path: 'media-planning',
            loadChildren: () =>
              import('@dmc-console/media-planning').then(
                (m) => m.mediaPlanningRoutes,
              ),
          },
          {
            path: 'campaign',
            loadChildren: () =>
              import('@dmc-console/campaign').then((m) => m.campaignRoutes),
          },
          {
            path: 'reporting',
            loadChildren: () =>
              import('@dmc-console/reporting').then((m) => m.reportingRoutes),
          },
          ...(!environment.production
            ? [
                {
                  path: 'data-editor-view',
                  loadChildren: () =>
                    import('@dmc-console/data-editor').then(
                      (m) => m.dataEditorRoutes,
                    ),
                },
              ]
            : []),
        ],
      },
    ],
  },
  { path: '**', redirectTo: RoutingUtils.pages.error404, pathMatch: 'full' },
];
