import { OverlayModule } from '@angular/cdk/overlay';
import {
  HttpEvent,
  HttpHandlerFn,
  HttpRequest,
  provideHttpClient,
  withFetch,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  inject,
} from '@angular/core';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import {
  DMC_CONSOLE_FEATURE_KEY,
  DmcConsoleEffects,
  dmcConsoleReducer,
} from '@dmc-console/shared';
import { IdentityService } from '@dmc-ng/authentication';
import {
  ACCOUNTS_FEATURE_KEY,
  AccountService,
  AccountsEffects,
  accountsReducer,
} from '@dmc-ng/data-access/accounts';
import {
  CAMPAIGN_REPORTINGS_FEATURE_KEY,
  CampaignReportingsEffects,
  CampaignReportingsService,
  campaignReportingsReducer,
} from '@dmc-ng/data-access/campaign-reportings';
import {
  CAMPAIGNS_FEATURE_KEY,
  CampaignsEffects,
  CampaignsService,
  campaignsReducer,
} from '@dmc-ng/data-access/campaigns';
import {
  COUNTINGS_FEATURE_KEY,
  CountingsEffects,
  CountingsService,
  countingsReducer,
} from '@dmc-ng/data-access/countings';
import {
  API_BASE_URL,
  CSV_URL,
  IS_PRODUCTION,
  PUBLIC_URL,
} from '@dmc-ng/data-access/injection-token';
import { MeEffects, MeService } from '@dmc-ng/data-access/me';
import {
  ORGANIZATION_USERS_FEATURE_KEY,
  OrganizationUsersEffects,
  OrganizationUsersService,
  organizationUsersReducer,
} from '@dmc-ng/data-access/organization-users';
import {
  OrganizationsEffects,
  OrganizationsService,
  fromOrganizationsActions,
} from '@dmc-ng/data-access/organizations';
import {
  PUBLIC_FEATURE_KEY,
  PublicEffects,
  PublicService,
  publicReducer,
} from '@dmc-ng/data-access/public';
import {
  RESOURCES_FEATURE_KEY,
  ResourcesEffects,
  ResourcesService,
  resourcesReducer,
} from '@dmc-ng/data-access/resources';
import { UserEffects, UserService, fromUser } from '@dmc-ng/data-access/user';
import { DialogEffects } from '@dmc-ng/dialog';
import {
  LoadChunkErrorHandler,
  errorHandlerEffects,
  errorHandlerFeature,
  errorHandlingInterceptor,
} from '@dmc-ng/error-handler';
import { LocalStorageService } from '@dmc-ng/local-storage';
import { OverlayEffects } from '@dmc-ng/overlay';
import { SnackbarEffects } from '@dmc-ng/ui/snackbar';
import { defaultInterceptor } from '@dmc-ng/utils';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { Observable } from 'rxjs';

import { appRoutes } from './app.routes';
import { environment } from './config/environment';

export const appConfig: ApplicationConfig = {
  providers: [
    MeService,
    OrganizationsService,
    UserService,
    AccountService,
    ResourcesService,
    CountingsService,
    CampaignsService,
    OrganizationUsersService,
    CampaignReportingsService,
    PublicService,
    LocalStorageService,
    provideRouter(appRoutes),
    provideEffects(
      errorHandlerEffects,
      UserEffects,
      OrganizationsEffects,
      OverlayEffects,
      DialogEffects,
      DmcConsoleEffects,
      MeEffects,
      AccountsEffects,
      ResourcesEffects,
      SnackbarEffects,
      CountingsEffects,
      CampaignsEffects,
      OrganizationUsersEffects,
      CampaignReportingsEffects,
      PublicEffects,
    ),
    provideStore(
      {
        router: routerReducer,
        errorHandler: errorHandlerFeature.reducer,
        [DMC_CONSOLE_FEATURE_KEY]: dmcConsoleReducer,
        [fromUser.USER_FEATURE_KEY]: fromUser.userReducer,
        [fromOrganizationsActions.ORGANIZATIONS_FEATURE_KEY]:
          fromOrganizationsActions.organizationsReducer,
        [ACCOUNTS_FEATURE_KEY]: accountsReducer,
        [RESOURCES_FEATURE_KEY]: resourcesReducer,
        [COUNTINGS_FEATURE_KEY]: countingsReducer,
        [CAMPAIGNS_FEATURE_KEY]: campaignsReducer,
        [ORGANIZATION_USERS_FEATURE_KEY]: organizationUsersReducer,
        [CAMPAIGN_REPORTINGS_FEATURE_KEY]: campaignReportingsReducer,
        [PUBLIC_FEATURE_KEY]: publicReducer,
      },
      {
        runtimeChecks: {
          // necessary to be disabled for sidepanel action
          strictActionImmutability: false,
        },
      },
    ),
    provideRouterStore(),
    provideStoreDevtools(),
    importProvidersFrom(
      BrowserModule,
      BrowserAnimationsModule,
      OverlayModule,
      MatSelectCountryModule.forRoot('en'),
      MatSnackBarModule,
      provideFirebaseApp(() => initializeApp(environment.firebase)),
      provideAuth(() => getAuth(getApp())),
    ),
    provideHttpClient(
      withInterceptorsFromDi(),
      withFetch(),
      withInterceptors([
        errorHandlingInterceptor,
        (
          httpRequest: HttpRequest<unknown>,
          next: HttpHandlerFn,
        ): Observable<HttpEvent<unknown>> => {
          const service = inject(IdentityService);
          const baseUrl = inject(API_BASE_URL);
          const interceptor = defaultInterceptor(baseUrl, () =>
            service.getIdToken(),
          );
          return interceptor(httpRequest, next);
        },
      ]),
    ),
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: (idSvc: IdentityService) => (): Promise<void> =>
        idSvc.initialize(),
      deps: [IdentityService],
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: API_BASE_URL, useValue: environment.apiUrl },
    { provide: PUBLIC_URL, useValue: environment.publicUrl },
    { provide: CSV_URL, useValue: environment.csvUrl },
    { provide: IS_PRODUCTION, useValue: environment.production },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline', subscriptSizing: 'dynamic' },
    },
    { provide: ErrorHandler, useClass: LoadChunkErrorHandler },
  ],
};
